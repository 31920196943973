import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { V1Participant } from '@wix/ambassador-challenge-service-web/types';
import { isUUID } from '../../../selectors/isUUID';
import { participantAPI } from '@wix/challenges-web-api/dist/src/API';
import { getUserFromConfig } from './userContextHelpers';
import userTypeHandlers from './userTypeHandlers';
import { isForcedPreviewVisitor } from '../../../selectors/isForcedPreview';
import { getChallengeId } from '../../ChallengeDataProvider/challengeDataProviderPropsMap';

export async function getParticipant(
  flowAPI: ControllerFlowAPI,
): Promise<V1Participant> {
  const user = getUserFromConfig(flowAPI.controllerConfig);
  const challengeId = await getChallengeId(flowAPI);

  /*
    Reject request for participant if owner wants to preview the Visitor page from BM.
   */
  if (
    isForcedPreviewVisitor(
      flowAPI?.controllerConfig?.wixCodeApi?.location?.query,
    )
  ) {
    return null;
  }

  if (user.loggedIn && isUUID(challengeId)) {
    try {
      const { participants } = await participantAPI.listParticipants({
        challengeId,
        memberId: user.id,
      });

      const activeParticipant = participants.find((participant) => {
        return !userTypeHandlers.isUserLeftOrRejected(
          participant.transitions[0].state,
        );
      });

      return activeParticipant || participants?.[0] || null;
    } catch (err) {
      console.error('[Challenges]: Error at get participant request.');
      console.error(err);
    }
  }

  return null;
}
