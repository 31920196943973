import {
  challengeAPI,
  participantAPI,
} from '@wix/challenges-web-api/dist/src/API';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { IParticipantSectionsContext } from './ParticipantSectionsContext';
import {
  ChallengeSection,
  ParticipantState,
} from '@wix/ambassador-challenge-service-web/types';
import {
  onParticipantLogin,
  userProviderPropsMap,
} from '../User/userProviderPropsMap';
import userTypeHandlers from '../User/helpers/userTypeHandlers';
import { challengesListDataProviderPropsMap } from '../ChallengesListDataProvider/challengesListDataProviderPropsMap';
import { toParticipantSections } from './toParticipantSections';
import { handleError } from '../ErrorHandler/errorHandlerPropsMap';
import { isForcedPreviewParticipant } from '../../selectors/isForcedPreview';
import { syncInstance } from '../../services/instance';
import { challengeDataProviderPropsMap } from '../ChallengeDataProvider/challengeDataProviderPropsMap';
import { isMockedChallenge } from '../main/getMockedChallenges';
import flatten from 'lodash/flatten';

const handleParticipantLogin = async (flowAPI: ControllerFlowAPI) => {
  flowAPI.controllerConfig.setProps({
    sections: await loadParticipantSections(flowAPI),
  });
};

const loadParticipantSections = async (
  flowAPI: ControllerFlowAPI,
): Promise<IParticipantSectionsContext['listParticipantSections']> => {
  const { isEditor, isPreview } = flowAPI.environment;
  const userProvider = await userProviderPropsMap(flowAPI);
  const { participant } = userProvider;
  const isJoinedParticipant =
    participant?.id &&
    userTypeHandlers.isJoinedAlready(participant?.transitions?.[0]?.state);
  const { challengeData } = await challengeDataProviderPropsMap(flowAPI);
  const challengeId = challengeData.challenge?.id;

  let sections: ChallengeSection[] = [];

  syncInstance(flowAPI);

  if (isMockedChallenge(challengeId, flowAPI)) {
    return [];
  }

  flowAPI.controllerConfig.setProps({
    isListParticipantSectionsRequestInProgress: true,
  });

  if (
    isJoinedParticipant &&
    userProvider.userType !== ParticipantState.RUNNING &&
    challengeData.challenge?.stepsSummary?.sectionsNumber > 0
  ) {
    try {
      sections = (
        await participantAPI.listSections({
          challengeId,
          participantId: participant?.id,
        })
      )?.sections;
    } catch (error) {
      handleError({ error, context: 'loadSections' });
    }
  }

  if (
    (isEditor ||
      isPreview ||
      isForcedPreviewParticipant(
        flowAPI?.controllerConfig?.wixCodeApi?.location?.query,
      )) &&
    !sections?.length
  ) {
    const challengeIdForPreview =
      challengeId ||
      (await challengesListDataProviderPropsMap(flowAPI)).challengesListData
        ?.memberChallenges?.[0]?.challenge?.id;
    if (challengeIdForPreview) {
      try {
        const ownerSections = (
          await challengeAPI.listSections({
            challengeId: challengeIdForPreview,
          })
        )?.sections;
        sections = toParticipantSections(ownerSections);
      } catch (error) {
        handleError({
          error,
          context: isEditor
            ? 'loadParticipantSections[editor]'
            : 'loadParticipantSections[preview]',
        });
      }
    }
  }

  flowAPI.controllerConfig.setProps({
    isListParticipantSectionsRequestInProgress: false,
  });

  return sections;
};

export const participantSectionsPropsMap = async function (
  flowAPI: ControllerFlowAPI,
): Promise<IParticipantSectionsContext> {
  onParticipantLogin(handleParticipantLogin);

  const sections = await loadParticipantSections(flowAPI);

  const steps = flatten(sections.map((section) => section.steps || []));

  return {
    isListParticipantSectionsRequestInProgress: false,
    listParticipantSections: sections,
    participantSteps: { steps },
    updateParticipantSections: async () => {
      const _sections = await loadParticipantSections(flowAPI);
      const _steps = flatten(sections.map((section) => section.steps || []));

      flowAPI.controllerConfig.setProps({
        listParticipantSections: _sections,
        participantSteps: { steps: _steps },
      });
    },
  };
};
