import { approvalAPI } from '@wix/challenges-web-api/dist/src/API';
import { ActionTypes, ScreenNames } from '../../BI/interfaces';
import { ApprovalRequestResolutionStatus } from '@wix/ambassador-challenge-service-web/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { biProviderPropsMap } from '../../BI/BIProviderPropsMap';
import { IUserContext } from '../UserContext';
import { handleError } from '../../ErrorHandler/errorHandlerPropsMap';
import { getChallengeId } from '../../ChallengeDataProvider/challengeDataProviderPropsMap';
import { Challenges } from '../../../editor/types/Experiments';
import { biBeforeAndAfter } from '../../../services/biHelpers';

async function getApprovalRequestId(
  flowAPI: ControllerFlowAPI,
  challengeId?: string,
) {
  challengeId = challengeId ? challengeId : await getChallengeId(flowAPI);
  try {
    const approvals = await approvalAPI.listApprovalRequests({
      challengeId,
      creatorId: flowAPI.controllerConfig.wixCodeApi.user.currentUser.id,
      includeResolved: false,
    });

    return approvals?.requests?.['0']?.id;
  } catch (error) {
    handleError({ error, context: 'cancelInvite.listApprovalRequests' });
  }
}

export async function cancelInvite(
  flowAPI: ControllerFlowAPI,
  userProvider: IUserContext,
  challengeId?: string,
) {
  const approvalRequestId = await getApprovalRequestId(flowAPI, challengeId);
  const isBiV2Enabled = flowAPI.experiments.enabled(Challenges.isBiV2Enabled);

  if (approvalRequestId) {
    const bi = biProviderPropsMap({
      biSettings: {
        screenName: ScreenNames.ChallengePage,
      },
      flowAPI,
    });

    if (isBiV2Enabled) {
      await biBeforeAndAfter(
        flowAPI.bi,
        ActionTypes.CHALLENGE_JOIN,
        (actionId) =>
          approvalAPI.resolveApprovalRequest({
            actionId,
            requestId: approvalRequestId,
            resolutionStatus: ApprovalRequestResolutionStatus.CANCELLED,
          }),
      );
    } else {
      await bi.beforeAndAfterAction(ActionTypes.CHALLENGE_JOIN, (actionId) =>
        approvalAPI.resolveApprovalRequest({
          actionId,
          requestId: approvalRequestId,
          resolutionStatus: ApprovalRequestResolutionStatus.CANCELLED,
        }),
      );
    }

    await userProvider.updateParticipant();
  } else {
    console.error("[Challenges]: Can't find approval request id.", {
      flowAPI,
    });
    return;
  }
}
