import { IInviteLinkProviderProps } from './interfaces';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getInvitationLink } from '../../selectors/getInvitationLink';
import { getChallengeId } from '../ChallengeDataProvider/challengeDataProviderPropsMap';
import { isMockedChallenge } from '../main/getMockedChallenges';

export async function inviteLinkPropsMap(
  flowAPI: ControllerFlowAPI,
): Promise<IInviteLinkProviderProps> {
  const { isEditor } = flowAPI.environment;
  const challengeId = await getChallengeId(flowAPI);

  if (isMockedChallenge(challengeId, flowAPI)) {
    return {
      inviteLink: '',
    };
  }

  try {
    const msid = flowAPI.controllerConfig.platformAPIs?.bi?.metaSiteId;

    const inviteLink =
      isEditor || !msid ? '' : await getInvitationLink(challengeId, msid);

    return {
      inviteLink,
    };
  } catch (error) {
    console.error(error);
  }

  return {
    inviteLink: '',
  };
}
