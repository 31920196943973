import { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';
import { createEventHandler } from '@wix/yoshi-flow-editor/tpa-settings';
import { ButtonState, ISettingsEvents } from './Widget/settingsEvents';
import { initApi } from '../../services/initApi';
import { providersPropsMap } from '../../contexts/main/providersPropsMap';
import { ScreenNames } from '../../contexts/BI/interfaces';

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}: ControllerParams) => {
  const { setProps } = flowAPI.controllerConfig;
  const publicData = controllerConfig.config.publicData.COMPONENT || {};

  initApi(flowAPI);

  // settings panel pub/sub https://github.com/wix-private/tpa-settings#events-between-settings-panel-and-applications
  const settingsEventsHandler = createEventHandler<ISettingsEvents>(publicData);
  // subscribe to event. Supports Typescript and events keys maybe only the keys of IEvents interface.
  settingsEventsHandler.on('buttonState', (value) => {
    setProps({
      buttonState: value,
    });
  });
  settingsEventsHandler.on('showMAEmptyList', (value) => {
    setProps({
      showMAEmptyList: value,
    });
  });
  // subscribe on reset. When setting panel is closed
  settingsEventsHandler.onReset(() => {
    setProps({
      buttonState: ButtonState.Default,
    });
  });

  return {
    async pageReady() {
      const initialProps = await providersPropsMap({
        biSettings: {
          screenName: ScreenNames.ChallengeListWidget,
          preventAutomaticallySendChallengeId: true,
        },
        flowAPI,
        enabledProviders: ['challengesListDataProvider', 'paidPlansProvider'],
      });

      flowAPI.bi.updateDefaults({
        origin: flowAPI.controllerConfig.wixCodeApi.window.viewMode,
        _uuid: flowAPI.controllerConfig.platformAPIs.bi.ownerId,
        screenName: ScreenNames.ChallengeListWidget,
      });

      try {
        flowAPI.controllerConfig.setProps({
          ...initialProps,
        });
      } catch (e) {
        console.error('[challenge list]: couldnt set initial props', e);
      }

      flowAPI.controllerConfig.setProps({
        editorLoaded: true,
      });
    },
    updateConfig($w, config) {
      // notify (events should be fired)
      settingsEventsHandler.notify(config.publicData.COMPONENT || {});
    },
  };
};

export default createController;
