import {
  paidPlansBenefitsApi,
  IBenefitWithPlanInfo,
} from '@wix/challenges-web-api/dist/src/API';
import { benefitsList } from '../api/benefits/benefitsList.api';
import { IUserPaidPlan } from '@wix/challenges-web-library/dist/src/types';
import { handleError } from '../contexts/ErrorHandler/errorHandlerPropsMap';
import { PlanStatus } from '@wix/ambassador-pricing-plan-benefits-server/types';

const PAID_PLANS_URL = '/_api/paid-plans/v1/plans/public';

const PAID_PLANS_LIMIT = 100;

async function recursiveLoadPP(
  loadedPP: IUserPaidPlan[],
  instance: string,
  origin: string,
  offset: number = 0,
): Promise<IUserPaidPlan[]> {
  const { plans }: { plans: IUserPaidPlan[] } = await (
    await fetch(
      `${origin}${PAID_PLANS_URL}?limit=${PAID_PLANS_LIMIT}&offset=${
        offset * PAID_PLANS_LIMIT
      }`,
      {
        method: 'GET',
        headers: {
          Authorization: instance,
        },
      },
    )
  ).json();

  const acc = [...loadedPP, ...plans];

  if (plans.length === PAID_PLANS_LIMIT) {
    await recursiveLoadPP(acc, instance, origin, ++offset);
  }

  return acc;
}

export const loadUserPaidPlans = async (
  httpClient: any,
  instance: string,
  origin: string,
  isServerlessExperimentEnabled: boolean,
) => {
  try {
    const plans = await recursiveLoadPP([], instance, origin);
    let paidPlansWithBenefits: IBenefitWithPlanInfo[] = [];

    if (isServerlessExperimentEnabled) {
      paidPlansWithBenefits = (await httpClient.request(benefitsList()))?.data
        ?.benefits;
    } else {
      paidPlansWithBenefits = await paidPlansBenefitsApi.listAllBenefits();
    }

    const paidPlansWithBenefitsActive = paidPlansWithBenefits?.filter(
      ({ planInfo }) => planInfo.status === PlanStatus.ACTIVE,
    );

    return paidPlansWithBenefitsActive.length
      ? paidPlansWithBenefitsActive.map(({ planInfo, benefit }) => {
          const plan = plans.find(({ id }) => {
            return id === planInfo.id;
          });

          return {
            id: planInfo.id,
            details: {
              id: planInfo.id,
              name: planInfo.name,
              description: plan?.details?.description || '',
            },
            challenges: benefit.resourceIds,
            paymentOptions: plan?.paymentOptions || {
              price: {
                amount: null,
                currency: null,
              },
              recurring: false,
              validFor: {
                forever: false,
                period: {
                  amount: null,
                  unit: null,
                },
              },
            },
            visible: planInfo.visible || false,
          };
        })
      : [];
  } catch (error) {
    handleError({ error, context: 'loadUserPaidPlans' });
  }
};
