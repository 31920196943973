import { IProvidersProps } from './intefaces';
import { biProviderPropsMap } from '../BI/BIProviderPropsMap';
import { ISettingsParams } from '@wix/yoshi-flow-editor/tpa-settings';
import { userProviderPropsMap } from '../User/userProviderPropsMap';
import { locationProviderPropsMap } from '../Location/locationProviderPropsMap';
import { challengeDataProviderPropsMap } from '../ChallengeDataProvider/challengeDataProviderPropsMap';
import { generalDataProviderPropsMap } from '../GeneralDataProvider/generalDataProviderPropsMap';
import { challengesListDataProviderPropsMap } from '../ChallengesListDataProvider/challengesListDataProviderPropsMap';
import { IBiSettings } from '../BI/interfaces';
import { inviteLinkPropsMap } from '../InviteLink/inviteLinkPropsMap';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { paidPlansPropsMap } from '../PaidPlans/paidPlansPropsMap';
import { participantStepsDataProviderPropsMap } from '../ParticipantStepsDataProvider/ParticipantStepsDataProviderPropsMap';
import { participantSectionsPropsMap } from '../ParticipantSections/ParticipantSectionsPropsMap';
import { resolveStepDataProviderPropsMap } from '../ResolveStep/ResolveStepDataProviderPropsMap';
import { loadingPropsMap } from '../Loading/loadingPropsMap';
import { errorHandlerPropsMap } from '../ErrorHandler/errorHandlerPropsMap';
import { localStoragePropsMap } from '../LocalStorage/localStoragePropsMap';

export type ToggleableProviders =
  | 'inviteLinkProvider'
  | 'challengeDataProvider'
  | 'challengesListDataProvider'
  | 'paidPlansProvider'
  | 'participantStepsDataProvider'
  | 'participantSectionsDataProvider'
  | 'resolveStepDataProvider';

export const providers: Record<
  ToggleableProviders,
  (flowAPI: ControllerFlowAPI) => Promise<any>
> = {
  challengeDataProvider: challengeDataProviderPropsMap,
  challengesListDataProvider: challengesListDataProviderPropsMap,
  inviteLinkProvider: inviteLinkPropsMap,
  paidPlansProvider: paidPlansPropsMap,
  participantStepsDataProvider: participantStepsDataProviderPropsMap,
  participantSectionsDataProvider: participantSectionsPropsMap,
  resolveStepDataProvider: resolveStepDataProviderPropsMap,
};
interface IRootProviderPropsMap<T> {
  biSettings?: IBiSettings;
  enabledProviders: ToggleableProviders[];
  flowAPI: ControllerFlowAPI;
}

export async function providersPropsMap<T extends ISettingsParams>(
  options: IRootProviderPropsMap<T>,
): Promise<IProvidersProps> {
  const { enabledProviders, biSettings, flowAPI } = options;

  const providersProps = {
    ...biProviderPropsMap({ biSettings, flowAPI }),
    ...generalDataProviderPropsMap(flowAPI),
    ...locationProviderPropsMap(flowAPI),
    ...loadingPropsMap(flowAPI),
    ...localStoragePropsMap(flowAPI),
  };

  const asyncProviders: Promise<any>[] = [
    errorHandlerPropsMap(flowAPI),
    userProviderPropsMap(flowAPI),
  ];

  enabledProviders.forEach((providerName) => {
    asyncProviders.push(providers[providerName](flowAPI));
  });

  const data = await Promise.all(asyncProviders);
  data.forEach((providerData) => {
    if (!providerData) {
      return;
    }
    Object.entries(providerData).forEach(([key, value]) => {
      providersProps[key] = value;
    });
  });

  return providersProps as any;
}
