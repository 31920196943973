export const CHALLENGE_PAGE_ID = 'challenge_page';
export const PAYMENT_PAGE_ID = 'Challenge Payment';
export const THANK_YOU_PAGE_ID = 'Thank You Page';
export const CHALLENGES_LIST_PAGE_ID = 'Challenges';

export const PAYMENT_PAGE_MANIFEST_KEY = 'paymentPage';
export const THANK_YOU_PAGE_MANIFEST_KEY = 'challengeThankYou';
export const CHALLENGES_LIST_PAGE_MANIFEST_KEY = 'challenges';
export const CHALLENGES_PAGE_MANIFEST_KEY = 'challenge_page_key';

export const CHALLENGE_PAYMENT_PAGE_TYPE =
  '0daa3702-0e20-48ca-ba15-ad380e00e6b6';
export const CHALLENGE_THANK_YOU_PAGE_TYPE =
  'a159b4d2-831b-410c-9e77-ec3f40c9cace';
export const CHALLENGES_LIST_PAGE_TYPE = '20dcd94b-f847-4e24-b3f2-0d3894593f7a';
export const CHALLENGES_LIST_WIDGET_TYPE =
  'ef4e59bd-625a-484e-9ba1-650d4aecf03b';
export const MA_CHALLENGES_LIST_PAGE_TYPE =
  '384628b7-c716-4410-8fc5-4e2bd5aad178';
export const MA_PROGRAMS_LIST_PAGE_TYPE =
  'c88902a1-7fbf-4ff1-b19a-af39c48bc740';
export const CHALLENGE_PAGE_TYPE = '847c2f5d-017f-499f-9c3e-069164fc116f';
export const CHALLENGES_PAGES = [
  CHALLENGE_PAGE_ID,
  PAYMENT_PAGE_ID,
  THANK_YOU_PAGE_ID,
  CHALLENGES_LIST_PAGE_ID,
];

export const CHALLENGES_PAGES_OLD_TITLES = {
  [CHALLENGE_PAGE_ID]: 'Challenge Page',
  [CHALLENGES_LIST_PAGE_ID]: 'Challenges',
};

export const PAGE_TITLES = {
  [CHALLENGE_PAGE_ID]: 'editor.initial.page-name.challenge-page',
  [PAYMENT_PAGE_ID]: 'editor.initial.page-name.payment-page',
  [THANK_YOU_PAGE_ID]: 'editor.initial.page-name.thank-you-page',
  [CHALLENGES_LIST_PAGE_ID]: 'editor.initial.page-name.challenge-list-page',
};

export const CHALLENGES_PLATFORM_CONTROLLER_TYPE = 'challengesPlatform';

export const MEMBERSHIP_APP_ID = '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3';
export const PP_SECTION_ID = 'membership_plan_picker_tpa';
export const GROUPS_APP_ID = '148c2287-c669-d849-d153-463c7486a694';

export type CHALLENGES_INSTALL_MIGRATION_TYPE =
  | 'install_pricing_plans'
  | 'install_groups';
