import {
  ChallengePricingSinglePaymentPaymentMethod,
  V1AccessType,
  V1Challenge,
  V1ChallengeState,
  V1DurationUnit,
} from '@wix/ambassador-challenge-service-web/types';

const MOCK_ID =
  'f2e85b46-014b-4c66-80f7-477d653014bb-sgsgseg-sgfsegsehdjtfjgyjgyjgyj';
let mockCount = 1;

const generateMockId = () => {
  return `${MOCK_ID}-${++mockCount}`;
};

export const flexibleChallengeWeeks: V1Challenge = {
  participantsSummary: {
    participantsNumber: 10,
  },
  id: generateMockId(),

  transitions: [{ state: [V1ChallengeState.PUBLISHED] as any }],
  settings: {
    description: {
      title: '3-Week Challenge with Price and 12 Participants',
      media: {},
    },
    accessRestrictions: {
      accessType: V1AccessType.PUBLIC,
      maxParticipants: 15,
    },
    timelineType: {
      flexible: {
        duration: {
          unit: V1DurationUnit.WEEKS,
          value: 3,
        },
      },
    },
    pricing: {
      singlePayment: {
        price: {
          currency: 'USD',
          amount: '123.01',
        },
        allowedMethods: [
          ChallengePricingSinglePaymentPaymentMethod.OFFLINE_PAYMENT,
        ],
      },
    },
  },
};

export const flexibleChallengeDay: V1Challenge = {
  participantsSummary: {
    participantsNumber: 10,
  },
  id: generateMockId(),

  transitions: [{ state: [V1ChallengeState.PUBLISHED] as any }],
  settings: {
    description: {
      title: '3-Week Challenge with Price and 12 Participants',
      media: {},
    },
    accessRestrictions: {
      accessType: V1AccessType.PUBLIC,
      maxParticipants: 15,
    },
    timelineType: {
      flexible: {
        duration: {
          unit: V1DurationUnit.WEEKS,
          value: 3,
        },
      },
    },
    pricing: {
      paidPlans: [{ id: 'f2e85b46-014b-4c66-80f7-477d653014bb' }],
    },
  },
};

export const userPaidPlans = [
  {
    id: 'f2e85b46-014b-4c66-80f7-477d653014bb',
    roleId: 'f2e85b46-014b-4c66-80f7-477d653014bb',
    details: {
      name: 'Plan Name',
      description: 'Tagline',
      ribbon: '',
      benefits: ['Plan benefits'],
      disclaimer: '',
    },
    challenges: [flexibleChallengeDay.id],
    paymentOptions: {
      price: {
        amount: '150',
        currency: 'USD',
      },
      recurring: false,
      validFor: {
        forever: false,
        period: {
          amount: 1,
          unit: 'MONTH',
        },
      },
    },
    visible: true,
    archived: false,
    primary: false,
    hasOrders: false,
    dateCreated: '2019-11-26T10:28:24.946Z',
    dateUpdated: '2019-11-26T10:28:24.946Z',
    slug: 'plan-name',
    template: false,
  },
];

const INITIAL_CHALLENGE: Omit<V1Challenge, 'timelineType'> = {
  id: generateMockId(),
  participantsSummary: {
    participantsNumber: 10,
  },

  transitions: [{ state: [V1ChallengeState.PUBLISHED] as any }],
  settings: {
    rewards: [],
    pricing: {},
    completionCriteria: {
      completedSteps: {
        all: true,
        minThreshold: {
          value: 10,
        },
      },
      completedMilestones: {
        minThreshold: {
          value: 10,
        },
        all: true,
      },
      scoredPoints: {
        value: 10,
      },
    },
    accessRestrictions: {
      maxParticipants: 15,
      accessType: V1AccessType.PUBLIC,
    },
  },
};

type IMockedChallenge = V1Challenge & { shouldTranslateTitle: boolean };

export const FLEXIBLE_CHALLENGE1: IMockedChallenge & { mocked: boolean } = {
  ...INITIAL_CHALLENGE,
  mocked: true, // to check on challenge page in editor
  shouldTranslateTitle: true, // for mocked challenges
  participantsSummary: {
    participantsNumber: 10,
    joinRequestsNumber: 0,
    invitationsNumber: 0,
  },
  stepsSummary: {
    stepsNumber: 15,
  },
  owners: [
    {
      id: 'a0a8750a-8dbe-4c75-8b15-54d9ed384fac',
      fullName: 'Site Owner',
      contactId: '99a65e13-d621-4e49-adf9-46d2fe944a6f',
    },
  ],
  settings: {
    ...INITIAL_CHALLENGE.settings,
    description: {
      media: {
        image: {
          width: 400,
          url: 'media/a0a875_9849fd794f234a5c82ff357a3ffa7164~mv2.jpg',
          id: 'a0a875_9849fd794f234a5c82ff357a3ffa7164~mv2.jpg',
          height: 300,
        },
      },
      title: 'editor.challenge.mocked-1.title',
      details:
        'Include a general overview of your challenge so that participants know what to expect. Also, tell participants about any prerequisites or special preparation they need to do before the challenge. You can write an inspirational message that motivates participants to give it their all and achieve their goals.',
    },
    timelineType: {
      flexible: {
        duration: {
          unit: V1DurationUnit.DAYS,
          value: 21,
        },
      },
    },
  },
};

export const FLEXIBLE_CHALLENGE2: IMockedChallenge = {
  ...INITIAL_CHALLENGE,
  shouldTranslateTitle: true,
  settings: {
    ...INITIAL_CHALLENGE.settings,
    description: {
      media: {
        image: {
          width: 400,
          id: 'a0a875_94a2a4699c3c4df187c6cda6969855e4~mv2.jpg',
          url: 'media/a0a875_94a2a4699c3c4df187c6cda6969855e4~mv2.jpg',
          height: 300,
        },
      },
      title: 'editor.challenge.mocked-2.title',
      details: '',
    },
    timelineType: {
      flexible: {
        duration: {
          unit: V1DurationUnit.DAYS,
          value: 21,
        },
      },
    },
  },
};

export const FLEXIBLE_CHALLENGE3: IMockedChallenge = {
  ...INITIAL_CHALLENGE,
  shouldTranslateTitle: true,
  settings: {
    ...INITIAL_CHALLENGE.settings,
    description: {
      media: {
        image: {
          width: 400,
          id: 'a0a875_0a13877b9eb1427d835fb3257eecde25~mv2.jpg',
          url: 'media/a0a875_0a13877b9eb1427d835fb3257eecde25~mv2.jpg',
          height: 300,
        },
      },
      title: 'editor.challenge.mocked-3.title',
      details: '',
    },
    timelineType: {
      flexible: {
        duration: {
          unit: V1DurationUnit.DAYS,
          value: 21,
        },
      },
    },
  },
};

export const CHALLENGES = [
  { challenge: FLEXIBLE_CHALLENGE1 },
  { challenge: FLEXIBLE_CHALLENGE2 },
  { challenge: FLEXIBLE_CHALLENGE3 },
];

const EDITORX_MEDIA = {
  MealForRunners: {
    image: {
      width: 1200,
      id: 'a0a875_ce06cede44414c9989603c9690070746~mv2.jpg',
      url: 'media/a0a875_ce06cede44414c9989603c9690070746~mv2.jpg',
      height: 1200,
    },
  },
  RunYourFirst: {
    image: {
      width: 1200,
      id: 'a0a875_a6961ed3f19b44d994235720b4a3cf42~mv2.jpg',
      url: 'media/a0a875_a6961ed3f19b44d994235720b4a3cf42~mv2.jpg',
      height: 1200,
    },
  },
  MarathonTraining: {
    image: {
      width: 1200,
      id: 'a0a875_2419a6dfc2a24ee9a003a63038f86dd3~mv2.jpg',
      url: 'media/a0a875_2419a6dfc2a24ee9a003a63038f86dd3~mv2.jpg',
      height: 1200,
    },
  },
};

const ADI_MEDIA = {
  RunYourFirst: {
    image: {
      width: 1200,
      id: 'a0a875_79ad2a8e8a9c4a1488ae11a705cf73b7~mv2.png',
      url: 'media/a0a875_79ad2a8e8a9c4a1488ae11a705cf73b7~mv2.png',
      height: 1200,
    },
  },
  SugarReset: {
    image: {
      width: 1200,
      id: 'a0a875_cc8c1bb839d84e8aa827b8fb1fd41449~mv2.png',
      url: 'media/a0a875_cc8c1bb839d84e8aa827b8fb1fd41449~mv2.png',
      height: 1200,
    },
  },
  BookClub: {
    image: {
      width: 1200,
      id: 'a0a875_9c012848fd41499da0c00f8a4037b2f7~mv2.png',
      url: 'media/a0a875_9c012848fd41499da0c00f8a4037b2f7~mv2.png',
      height: 1200,
    },
  },
};

export const CHALLENGES_EDITORX = [
  {
    challenge: {
      ...FLEXIBLE_CHALLENGE1,
      settings: {
        ...FLEXIBLE_CHALLENGE1.settings,
        description: {
          ...FLEXIBLE_CHALLENGE1.settings.description,
          media: EDITORX_MEDIA.RunYourFirst,
        },
      },
    },
  },
  {
    challenge: {
      ...FLEXIBLE_CHALLENGE2,
      settings: {
        ...FLEXIBLE_CHALLENGE2.settings,
        description: {
          ...FLEXIBLE_CHALLENGE2.settings.description,
          media: EDITORX_MEDIA.MarathonTraining,
        },
      },
    },
  },
  {
    challenge: {
      ...FLEXIBLE_CHALLENGE3,
      settings: {
        ...FLEXIBLE_CHALLENGE3.settings,
        description: {
          ...FLEXIBLE_CHALLENGE3.settings.description,
          media: EDITORX_MEDIA.MealForRunners,
        },
      },
    },
  },
];

export const CHALLENGES_ADI = [
  {
    challenge: {
      ...FLEXIBLE_CHALLENGE1,
      settings: {
        ...FLEXIBLE_CHALLENGE1.settings,
        description: {
          ...FLEXIBLE_CHALLENGE1.settings.description,
          media: ADI_MEDIA.RunYourFirst,
        },
      },
    },
  },
  {
    challenge: {
      ...FLEXIBLE_CHALLENGE1,
      participantsSummary: {
        participantsNumber: 5,
      },
      stepsSummary: {
        stepsNumber: 56,
      },
      timelineType: {
        flexible: {
          duration: {
            unit: V1DurationUnit.DAYS,
            value: 56,
          },
        },
      },
      settings: {
        ...FLEXIBLE_CHALLENGE2.settings,
        description: {
          ...FLEXIBLE_CHALLENGE1.settings.description,
          title: 'editor.challenge.mocked-4.title',
          media: ADI_MEDIA.SugarReset,
        },
      },
    },
  },
  {
    challenge: {
      ...FLEXIBLE_CHALLENGE1,
      participantsSummary: {
        participantsNumber: 30,
      },
      stepsSummary: {
        stepsNumber: 5,
      },
      timelineType: {
        flexible: {
          duration: {
            unit: V1DurationUnit.DAYS,
            value: 25,
          },
        },
      },
      settings: {
        ...FLEXIBLE_CHALLENGE2.settings,
        description: {
          ...FLEXIBLE_CHALLENGE1.settings.description,
          title: 'editor.challenge.mocked-5.title',
          media: ADI_MEDIA.BookClub,
        },
      },
    },
  },
];
