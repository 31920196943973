import {
  setChallengesServiceApiBaseUrl,
  paidPlansBenefitsApi,
} from '@wix/challenges-web-api/dist/src/API';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { syncInstance, setInstance } from './instance';

export function initApi(flowAPI: ControllerFlowAPI) {
  const { appDefinitionId, isSSR, isViewer } = flowAPI.environment;

  if (!isViewer || isSSR) {
    setChallengesServiceApiBaseUrl(
      'https://editor.wixapps.net/_api/challenge-service-web',
    );
  }

  paidPlansBenefitsApi.setBaseUrl(
    'https://www.wix.com/_api/challenges-web-proxy/pricing-benefits-proxy',
  );

  syncInstance(flowAPI);

  flowAPI.controllerConfig.wixCodeApi.site.onInstanceChanged(({ instance }) => {
    setInstance(instance, flowAPI);
  }, appDefinitionId);
}
