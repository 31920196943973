import { challengeAPI } from '@wix/challenges-web-api/dist/src/API';
import { handleError } from '../contexts/ErrorHandler/errorHandlerPropsMap';

export const getInvitationLink = async (
  challengeId: string,
  metaSiteId: string,
): Promise<string> => {
  let inviteLink = '';

  try {
    if (challengeId) {
      const {
        invitationLink: { url },
      } = await challengeAPI.getInvitationLink({ challengeId });

      inviteLink = url;
    }
  } catch (error) {
    handleError({
      error,
      context: 'getInvitationLink',
      muteError: true,
      preventSentryReport: true,
    });
  }

  return (
    inviteLink || `https://apps.wix.com/place-invites/join-lp/${metaSiteId}`
  );
};
