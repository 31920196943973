import uuid from 'uuid';
import {
  memberWebAppOnBeforeAction,
  memberWebOnAfterAction,
} from '@wix/bi-logger-challenges-member-web/v2';
import { ActionTypes } from '../contexts/BI/interfaces';

export const biBeforeAndAfter = async (
  bi: any,
  actionType: ActionTypes,
  fn: Function,
) => {
  const actionId = uuid();

  await bi.report(
    memberWebAppOnBeforeAction({
      actionId,
      actionType,
      // screenName,
    }),
  );

  const response = await fn(actionId);

  await bi.report(
    memberWebOnAfterAction({
      actionId,
      actionType,
      // screenName,
    }),
  );

  return response;
};
