import {
  CHALLENGES,
  CHALLENGES_ADI,
  CHALLENGES_EDITORX,
} from '../../components/ChallengesList/Widget/mocks';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export const getMockedChallenges = (flowAPI: ControllerFlowAPI) => {
  const { isEditorX } = flowAPI.environment;
  const isAdi =
    flowAPI.controllerConfig.wixCodeApi.location.query?.dsOrigin ===
    'onboarding';

  let mockedChallenges = CHALLENGES;

  if (isAdi) {
    mockedChallenges = CHALLENGES_ADI;
  }

  if (isEditorX) {
    mockedChallenges = CHALLENGES_EDITORX;
  }

  return {
    memberChallenges: mockedChallenges,
    totalCount: mockedChallenges.length,
  };
};

export const isMockedChallenge = (
  slugOrChallengeId: string,
  flowAPI: ControllerFlowAPI,
) => {
  return !!getMockedChallenge(slugOrChallengeId, flowAPI);
};

export const getMockedChallenge = (
  slugOrChallengeId: string,
  flowAPI: ControllerFlowAPI,
) => {
  const mockedChallenge = getMockedChallenges(flowAPI)?.memberChallenges?.find(
    (challenge) => challenge?.challenge?.id === slugOrChallengeId,
  );

  return mockedChallenge?.challenge;
};
