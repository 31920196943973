import {
  ChallengeSection,
  ParticipantSection,
  ParticipantSectionStateTransition,
} from '@wix/ambassador-challenge-service-web/types';
import { addDays, format } from 'date-fns';
import { toParticipantSteps } from '../ParticipantStepsDataProvider/toParticipantSteps';

export const toParticipantSections = (
  ownerSections: ChallengeSection[],
): ParticipantSection[] => {
  return ownerSections.map((section) => {
    return {
      id: section?.id,
      source: section,
      steps: toParticipantSteps({ steps: section?.steps || ([] as any) }),
      transitions: convertToSectionTransition(section),
    };
  });
};

const convertToSectionTransition = (
  section: ChallengeSection,
): ParticipantSectionStateTransition[] => {
  const now = new Date();

  const sectionWaitingDate = section.settings.startCondition?.delay?.value || 0;

  if (sectionWaitingDate > 0) {
    const waitingDate = addDays(now, sectionWaitingDate);

    return [
      {
        waitingDate: {
          startDate: format(waitingDate, 'yyyy-MM-dd'),
        },
      },
    ];
  }

  const formattedNow = format(now, 'yyyy-MM-dd');
  return [
    {
      running: {
        dateInterval: {
          start: formattedNow,
          finish: formattedNow,
        },
      },
    },
  ];
};
