import {
  V1ListStepsResponse,
  V1ParticipantStep,
  ParticipantStepState,
} from '@wix/ambassador-challenge-service-web/types';
import { format, addDays } from 'date-fns';

const currDate = new Date();

export const toParticipantSteps = (
  ownerSteps: V1ListStepsResponse,
): V1ParticipantStep[] => {
  return ownerSteps.steps.map((step) => {
    const delay =
      (step?.settings?.embedding?.startConditions[0]?.delay?.value as number) ||
      0;

    const startDate = addDays(currDate, delay);
    const finishDate = addDays(currDate, delay + 1);

    return {
      id: step?.id,
      dateFrame: {
        start: format(startDate, 'yyyy-MM-dd'),
        finish: format(finishDate, 'yyyy-MM-dd'),
      },
      source: step,
      transitions: [{ state: ParticipantStepState.RUNNING }],
    };
  });
};
