import {
  paidPlansBenefitsApi,
  setCommonHeaders,
} from '@wix/challenges-web-api/dist/src/API';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { handleError } from '../contexts/ErrorHandler/errorHandlerPropsMap';

/*
  Set an instance from flowAPI or Query.
  */
export const syncInstance = (
  flowAPI: ControllerFlowAPI,
  shouldUpdateController: boolean = false,
) => {
  const {
    controllerConfig: { wixCodeApi },
    environment: { appDefinitionId },
  } = flowAPI;
  const instance =
    wixCodeApi.location?.query?.instance ||
    wixCodeApi.site.getAppToken(appDefinitionId);

  if (instance) {
    if (shouldUpdateController) {
      flowAPI.controllerConfig.setProps({
        instance,
      });
    }

    setInstance(instance, flowAPI);
  } else {
    handleError({
      error: new Error("Can't get instance"),
      context: 'instance',
    });
  }
};

/*
  Set the instance clearly by passing it.
 */
export const setInstance = (instance: string, flowAPI?: ControllerFlowAPI) => {
  const rest = {};

  // we need to pass ML header to our BE if its enabled
  if (flowAPI?.environment?.multilingual.isEnabled) {
    const currMLLanguage = flowAPI.environment.multilingual.siteLanguages.find(
      (lang) =>
        lang?.languageCode === flowAPI.environment.multilingual.currentLanguage,
    );
    if (currMLLanguage) {
      rest[
        'x-wix-linguist'
      ] = `${currMLLanguage?.languageCode}|${currMLLanguage?.locale}|${currMLLanguage?.isPrimaryLanguage}|${flowAPI.environment.appDefinitionId}`;
    }
  }

  setCommonHeaders({
    ...rest,
    Authorization: instance,
  });

  paidPlansBenefitsApi.setHeaders({
    Authorization: instance,
  });
};
