import { ActionTypes, ScreenNames } from '../../BI/interfaces';
import { participantAPI } from '@wix/challenges-web-api/dist/src/API';
import { biProviderPropsMap } from '../../BI/BIProviderPropsMap';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getChallengeId } from '../../ChallengeDataProvider/challengeDataProviderPropsMap';
import {
  CreateJoinRequestResponse,
  V1Participant,
} from '@wix/ambassador-challenge-service-web/types';
import { Challenges } from '../../../editor/types/Experiments';
import { biBeforeAndAfter } from '../../../services/biHelpers';

export async function sendRequestForOwnersApprove(
  flowAPI: ControllerFlowAPI,
  timeZone: string,
  startDate,
): Promise<V1Participant> {
  const bi = biProviderPropsMap({
    biSettings: {
      screenName: ScreenNames.ChallengePage,
    },
    flowAPI,
  });
  const challengeId = await getChallengeId(flowAPI);
  const isBiV2Enabled = flowAPI.experiments.enabled(Challenges.isBiV2Enabled);

  let participant = null;

  if (isBiV2Enabled) {
    participant = (
      await biBeforeAndAfter(
        flowAPI.bi,
        ActionTypes.JOIN_REQUEST_CREATE,
        (actionId) =>
          participantAPI.createJoinRequest({
            challengeId,
            memberId: flowAPI.controllerConfig.wixCodeApi.user.currentUser.id,
            timeZone,
            startDate,
            actionId,
          }),
      )
    ).participant;
  } else {
    participant = (
      await bi.beforeAndAfterAction<CreateJoinRequestResponse>(
        ActionTypes.JOIN_REQUEST_CREATE,
        (actionId) =>
          participantAPI.createJoinRequest({
            challengeId,
            memberId: flowAPI.controllerConfig.wixCodeApi.user.currentUser.id,
            timeZone,
            startDate,
            actionId,
          }),
      )
    ).participant;
  }

  return participant;
}
